<template>
  <div class="user-container">
    <div class="operator-bar">
      <el-button type="primary" icon="el-icon-edit" @click="handleCreate">新建账号</el-button>
    </div>

    <el-table :data="listData" max-height="570" v-loading="loading == 'list'">
      <el-table-column type="index" :index="indexMethod" label="序号" width="100">
      </el-table-column>

      <el-table-column prop="userName" label="用户名" min-width="200" show-overflow-tooltip>
      </el-table-column>
      
      <el-table-column prop="phone" label="手机号" width="200">
      </el-table-column>

      <el-table-column prop="role.roleName" label="角色" width="200">
      </el-table-column>

      <el-table-column prop="userStatus" label="状态" width="200">
        <template slot-scope="{ row }">
          <el-tag :type="row.userStatus | statusFilter">
            {{ row.userStatus | formatStatus }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="100">
        <template slot-scope="{ row }">
          <el-button type="primary" @click="goDetail(row.userId)" size="mini">详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="listPage.total>0" :total="listPage.total" :page.sync="listPage.page" :limit.sync="listPage.pageSize" @pagination="getList" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getUserList, getUserListCount} from '@/api/user'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      listData:[],
      loading: false,
      listPage: {
        total: 0,
        page: 1,
        pageSize : 10,
      }
    }
  },
  created() {
    this.getListCount()
    this.getList()
  },
  methods: {
    indexMethod(index) {
      return this.listPage.pageSize*(this.listPage.page-1)+index+1
    },
    getListCount() {
      getUserListCount().then(res => {
        this.listPage.total = res.data.result
      })
    },
    getList() {
      const params = {
        page: this.listPage.page,
        pageSize: this.listPage.pageSize,
      }
      this.loading = 'list'
      getUserList(params).then(res => {
        this.listData = res.data.result
        this.loading = false
      })
    },
    handleCreate(){
      this.$router.push('/account/user/edit')
    },
    goDetail(userId) {
      this.$router.push('/account/user/detail?userId='+userId)
    }
  },
  filters: {
    // el-tag类型转换
    statusFilter(status) {
      const statusMap = {
        'DISABLE': 'info',
        'NORMAL': 'success',
      }
      return statusMap[status]
    },
    // 状态显示转换
    formatStatus(status) {
      const statusMap = {
        'DISABLE': '停用',
        'NORMAL': '正常',
      }
      return statusMap[status]
    }
  }
}
</script>